import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://pybit.es/codechallenge07.html"
      }}>{`Pybites challenge 07`}</a>{` challenges us to create a sentiment analysis script that takes tweets related to a term and does some analysis in order to give us a positive/negative percentage of the general opinion of a term.`}</p>
    <p>{`Since I was learning how to best user NLTK I have decided to use this library for the challenge. Working on this project was quite fun and I have learned quite a lot. In this post, I will show you I will tell you about my mistakes, how I worked around them and how to improve the classifier speed training.`}</p>
    <h1 {...{
      "id": "problem-training-the-classifier-on-every-tweet",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#problem-training-the-classifier-on-every-tweet",
        "aria-label": "problem training the classifier on every tweet permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Problem: Training the classifier on every tweet`}</h1>
    <p>{`My first mistake was running the method that trains the classifier on every tweet received. So if there are 2,000 tweets to analyse and each training takes 1 minute (it's more than that), it will take ages until you get the score of the term that you searched.`}</p>
    <p>{`I didn't save this code but basically, I created a single function that did two things: it trained the classifier and gave the classification of a term. Straight away you can see that this was bad practice, things should be modular and in this case, the function should only do one thing.`}</p>
    <p>{`Imagine scrapping 1,000 tweets and training the classifier on every single one of them in order to get the classification. This was a huge time sink and bad approach to the problem since the classifier data didn't change, it should be trained only once and that's it.`}</p>
    <h1 {...{
      "id": "solution-initializing-the-classifier-on-init",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#solution-initializing-the-classifier-on-init",
        "aria-label": "solution initializing the classifier on init permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Solution: initializing the classifier on `}<strong parentName="h1">{`init`}</strong></h1>
    <p>{`The solution was obvious - train the classifier once and be done with it.`}</p>
    <p>{`At first, I was unsure how to do this, but then I realised that since the classifier was a class, I could just call the class once, train the classifier and then call a method on the class to get the classification of a term.`}</p>
    <p>{`After experimenting a bit with the code, I've decided to try to initialize the method that trains the classifier on the `}<inlineCode parentName="p">{`__init__`}</inlineCode>{` of the class. A soon as the class is instantiated the training of the classifier is the first thing to be done.`}</p>
    <p>{`This speeded up the classification of 1000 tweets by a lot, but there is still room for improvement after all, the training data is still unchanged at this point. I had to come up with some way to optimize this process somehow.`}</p>
    <h1 {...{
      "id": "optimization-using-pickle-to-saveload-classifier",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#optimization-using-pickle-to-saveload-classifier",
        "aria-label": "optimization using pickle to saveload classifier permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Optimization: using pickle to save/load classifier`}</h1>
    <p>{`The only way to optimize the classifier and make it blazing fast would be to train it once, save the trained classifier somehow and use it in all other future terms that we wish to classify.`}</p>
    <p>{`After a quick search, I came across the `}<em parentName="p">{`pickle`}</em>{` library, it was exactly what I needed. Pickling an object will simply save the object in its current state, you can then unpickle it whenever you want to use it in the future.`}</p>
    <p>{`After the classifier finishes its training, I pickled it and added a piece of code to check if the pickled file with the name `}<inlineCode parentName="p">{`classifier.pickle`}</inlineCode>{` is found in the directory of the program. If it is, then we have a trained classifier and we can simply load it and skip the training. `}</p>
    <p>{`Pickling a class method is as simple and doing `}<inlineCode parentName="p">{`pickle.dump()`}</inlineCode>{`  on the method that we wish to save. To load up all we need is to add `}<inlineCode parentName="p">{`pickle.load`}</inlineCode>{` to unpickle the file and return the classifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`    def save_classifier(self, classifier):
        with open('classifier.pickle', 'wb') as save_classifier:
            pickle.dump(classifier, save_classifier)

    def load_classifier(self):
        with open("classifier.pickle", "rb") as loaded_classifier:
            return pickle.load(loaded_classifier)
`}</code></pre>
    <p>{`These were the two methods that I added to the `}<inlineCode parentName="p">{`TweetsClassifier`}</inlineCode>{` class in order to call them on every run of the program.`}</p>
    <h1 {...{
      "id": "final-thoughts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#final-thoughts",
        "aria-label": "final thoughts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Final thoughts`}</h1>
    <p>{`This was my first attempt at creating a classifier and my decision to just use the NLTK library probably made the program run a little bit slower than if I had opted for other libraries which use vectors in order to train and classify the data.`}</p>
    <p>{`The classifier is not very accurate (79% accurate) due to the false positives (such as sarcasm) or due to repetitive tweets (this issue I finished by using a set of all the tweets gathered).`}</p>
    <p>{`None the less, this was a very interesting exercise that gave me much pleasure when I saw the results of a term based off on twitter opinions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      